<template>
  <div class="page10">
    <img class="page10-img" src="../../assets/pages/obj_10.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'page10',
}
</script>

<style scoped lang="scss">
.page10 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page10-img {
    height: 100%;
  }
}
</style>